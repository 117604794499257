define('autoload/modules',['require','modules/core/module','modules/security/oauth2/module'],function (require) {
    'use strict';

    return function (app) {
      /** Autoload modules */
      require('modules/core/module')(app);
      require('modules/security/oauth2/module')(app); //dependent on 'core' module
    };

});


