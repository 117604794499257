define('modules/security/oauth2/OAuth2SecurityService',['require','marionette','backbone','underscore','URI','URITemplate','jquery','routefilter','./OAuth2User'],function(require) {
    'use strict';
    
    var Marionette = require('marionette');
    var Backbone = require('backbone');
    var _ = require('underscore');
    var URI = require('URI');
    var URITemplate = require('URITemplate');
    var $ = require('jquery');
    require('routefilter');
    
    var OAuth2User = require('./OAuth2User');
    
    var OAuth2SecurityService = Marionette.Object.extend({
        __name__ : 'com.dw.security.OAuth2Service',
        
        config : undefined,
        
        oAccessToken : undefined,

        initialize : function(options) {
            var self = this;
            self.logger().debug('security service invoked');
            self.app = options.app;
            self.app.on('start', function(options){
                self.console().debug('initialize:: on app start. options', options.oauth2security);
                self.config = options.oauth2security;
            });
            self.dao = options.dao;
            Backbone.Router.prototype.before = _.bind(self._routeFilterBefore, self);
        },

        getCurrentUser : function() {
            return this.mCurrentUser;
        },
        
        
        loadCurrentUser: function() {
            var self  = this;
            var deferred = $.Deferred();
            
            var request = self.dao.getCurrentUser();
            request.done(function(oUser) {
                self.mCurrentUser = new OAuth2User(oUser);
                deferred.resolve(self.mCurrentUser);
            });
            return deferred.promise();
        },

         logout : function() {
            var self = this;
            var config = self.config;
            var authURI = new URI(config.authURL);
            var logoutTemplate = new URITemplate('http://' + authURI.host() + '/logout' + '{?target}');
            var sLogoutURL = logoutTemplate.expand({
                target : config.logoutSuccessURL
            });
            window.location = sLogoutURL;
        },
        
        _routeFilterBefore : function() {
            var self = this;
            
            if(self.config.disabled) {
              //Disabled sub-sequent access
              if(self.getAccessToken()) {
                return;
              }
              
              //Disabled first access
              self.logger().trace('_routeFilterBefore:: Security is disabled' +
                      'so, using mock accessToken');
              self.setAccessToken('accessToken');
              self.app.trigger('access-token-ready', 'accessToken');
              self.app.view.show();
              return;
            }
            
            
            // If we already have accessToken don't need to do anything.
            if(self.getAccessToken()) {
                self.logger().trace('AccessToken is already with us. So, no action is needed');
                self.app.view.show();
                return;
            }

            // If accessToken is already in the current URL, parse it, store it and navigate
            // user back to the last accessed path.
            var oAccessToken = self._parseAccessToken(window.location.hash.substring(1));
            if (oAccessToken) {
                self.setAccessToken(oAccessToken);
                self.logger().trace('accessToken is parsed');
                
                //trigger event on app
                self.app.trigger('access-token-ready', oAccessToken.accessToken);

                var oResponseQueryParams = new URI.parseQuery(window.location.hash.substring(1));
                var sLastAccessedPath = oResponseQueryParams.state;

                self.loadCurrentUser().done(function() {
                    self.app.modules.core.bootstrapService.start().done(function(path) {
                        self.app.vent.trigger('current-user-ready', self.getCurrentUser());
                        self.app.view.show();
                        if(path){
                          Backbone.history.navigate(path, {trigger: true, replace: true});
                            return;
                        }
                        Backbone.history.navigate(sLastAccessedPath, {trigger: true, replace: true});
                    }).fail(function(message) {
                        self.logger().error('Bootstrap failed. reason:' + message);
                    });
                });
                return false;
            }
            
            // Otherwise, redirect user to login page
            self.login();
            return false;
            
        },
        
        
        getAccessToken : function() {
            return this.oAccessToken;
        },
        
        setAccessToken : function(oAccessToken) {
            var self = this;
            self.oAccessToken = oAccessToken;
        },

        /**
         * Redirects user to auth-server's login page.
         */
        login : function() {
            var self = this;
            var config = self.config;
            var url = new URITemplate(config.authURL + '{?response_type}{&client_id}{&redirect_uri}{&state}{&scopes}');
            var redirectURL = window.location.href.split('#')[0];
            self.logger().debug('login:: redirectURL:' + redirectURL);
            var data = {
                response_type : 'token',
                client_id : config.clientId,
                redirect_uri : redirectURL,
                state : window.location.hash.substring(1),
                scopes : config.scopes.join('+')
            };
            var authURL = url.expand(data);
            self.logger().debug('login:: final authURL:' + authURL);
            window.location = authURL;

        },

        /**
         * Parses accessToken from 'queryParams' string. Required fields of accessToken are 'accessToken', 'expiresIn',
         * 'tokenType'.If all the required fields of access token are present then only it returns plain javascript
         * object representing accessToken. Otherwise, it returns null.
         */
        _parseAccessToken : function(queryParams) {
            var self = this;
            if (!arguments) {
                throw new Error('QueryParams not found');
            }
            self.logger().debug('_parseAccessToken:: queryParams=' + queryParams);
            try {
                var oQueryParams = URI.parseQuery(queryParams);
                if ('access_token' in oQueryParams && 'expires_in' in oQueryParams && 'token_type' in oQueryParams) {
                    return {
                        accessToken : oQueryParams.access_token,
                        expiresIn : oQueryParams.expires_in,
                        tokenType : oQueryParams.token_type
                    };
                }

            } catch (e) {
                throw new Error('Failed to parse queryParams, make sure you have provided valid queryParams');
            }
            return null;
        }
    });

    return OAuth2SecurityService;
});

