define('components/BackbonePolymerBinder',['require','underscore','backbone','marionette'],function(require) {
    'use strict';
    
    var _ = require('underscore');
    var Backbone = require('backbone');
    var Marionette = require('marionette');
    
    var BindModelToPolymer = Marionette.Object.extend({
    	
    	//Polymer element
    	el : undefined,
    	
    	data : undefined,
    	
    	//{ base + observer : { data(model or collection), Handlers} map to unbind events
    	_oObserverListnersMap : undefined,
    	
    	
    	_oCollectionChangeListeners : undefined,
    	
    	//Default options
    	_oDefOptions : {
    		data : undefined,			//Mandatory | Backbone.Model or Backbone.Collection
    		polymerEl : undefined,		//Mandatory | Polymer element
    		observers : undefined,		//Optional  | String or Array
    		polymerBasePath : undefined //Optional  | String
    	},
    	
    	/**
    	 * Constructor
    	 * @param options
    	 */
    	initialize : function(options) {
            var self = this;
            options = _.extend({}, self._oDefOptions, options);
            
            //Mandatory validation
            if (!options.data) {
                throw new Error('insufficient options: data is not found');
            }
            
            if (!options.data instanceof Backbone.Model && !options.data instanceof Backbone.Collection) {
                throw new Error('Invalid options: data must be instanceof Backbone.Model or Backbone.Collection');
            }
            
            if (!options.polymerEl) {
                throw new Error('insufficient arguments: polymerEl is not found');
            }
            
            self._oObserverListnersMap = {};
            self._oCollectionChangeListeners = {};
            self.el = options.polymerEl;
            self.data = options.data;
            options.polymerBasePath = options.polymerBasePath || '';
            options.observers = self.normalizeObservers(options.observers);
            if(!options.observers.length) {
            	options.observers.push('');
            }
            
            if(options.data instanceof Backbone.Model){
            	self.bindModel(options.data, options.observers, options.polymerBasePath);
            }
            else if(options.data instanceof Backbone.Collection){
            	self.bindCollection(options.data, options.observers, options.polymerBasePath);
            }
            
            self.aPendingExecutions = [];
        },
        
        /**
         * normalize observers array. eg. ['project.owner', 'project'] -> ['project.owner']
         * @param observers | Array or String
         * @returns Array
         */
        normalizeObservers : function(observers)
        {
        	if(!_.isArray(observers)) {
        		return [''];
        	}
        	
        	//TODO normalize array. eg. ['project.owner', 'project'] -> ['project.owner']
        	return observers;
        },
        
        bindModelOneObserver : function(model, observer, polymerBasePath, storeBasePath)
        {
        	var self = this;
        	if(!model){
        		return;
        	}
        	
        	if(!self.getListeners(storeBasePath))
    		{
        	    /* jshint unused: false */
        		self._listenTo(model, 'change', storeBasePath, function(model, value) {
    				_.each(model.changed, function(value, key){
    				    var handle = window.setTimeout(function(){
    				        var newValue = model.get(key);
    				        if(!(newValue instanceof Backbone.Collection && newValue == model.previous(key))){
    				            if(newValue instanceof Backbone.Model){
    				                newValue = newValue.toLiveJSON();
    				            }
    				            self.modelChanged({
    				                path: polymerBasePath + '.' + key,
    				                value: _.isUndefined(newValue) ?  null : newValue
    				            });
    				        }
    				    }, 0);
    				    self.aPendingExecutions.push(handle);
    				});
        		});
        		/* jshint unused: true */
    		}
        	
        	if(!observer){
        		return;
        	}
        	
        	var aSplit = self.splitObserverAtFirst(observer);
        	var sKeyName = aSplit[0];
        	var sRestObserver = aSplit[1];
        	var value = model.get(sKeyName);
        	
        	if(value instanceof Backbone.Model){
        		
        		self._listenTo(model, 'change:' + sKeyName, storeBasePath, function(model) {
        		    window.setTimeout(function(){
        		        self.unbindModelObserver(sRestObserver, storeBasePath + '.' + sKeyName);
        		        self.bindModelOneObserver(model.get(sKeyName), sRestObserver, polymerBasePath +'.' + sKeyName, storeBasePath + '.' + sKeyName);
        		    }, 0);
            	});
        		self.bindModelOneObserver(value, sRestObserver, polymerBasePath + '.' + sKeyName, storeBasePath + '.' + sKeyName);
        		
        	}
        	else if(value instanceof Backbone.Collection){
        		
        		self._listenTo(model, 'change:' + sKeyName, storeBasePath, function(model) {
        			if(model.get(sKeyName) != model.previous(sKeyName)){
        			    window.setTimeout(function(){
        			        self.unbindCollectionObserver(model.previous(sKeyName), sRestObserver, polymerBasePath + '.' + sKeyName, storeBasePath + '.' + sKeyName);
        			        self.bindCollectionOneObserver(model.get(sKeyName), sRestObserver, polymerBasePath + '.' + sKeyName, storeBasePath + '.' + sKeyName);
        			    }, 0);
        			}
            	});
        		self.bindCollectionOneObserver(value, sRestObserver, polymerBasePath + '.' + sKeyName, storeBasePath + '.' + sKeyName);
        	}
        },
        // Code here will be linted with JSHint.
        /* jshint ignore:start */
        _bindCollectionOneObserverAfter : function(collection, observer, polymerBasePath, storeBasePath, at, howmany)
        {
        	var self = this;
        	_.each(collection.slice(at, howmany), function(model, index){
        		self.bindModelOneObserver(model, observer, polymerBasePath + '.' + index, storeBasePath + '.' + self.createKeyForCollection(model, index));
        	});
        },
        // Code here will be ignored by JSHint.
        /* jshint ignore:end */
        
        bindCollectionOneObserver : function(collection, observer, polymerBasePath, storeBasePath)
        {
        	var self = this;
        	if(!collection){
        		return;
        	}
        	
        	self._bindCollectionOneObserverAfter(collection, observer, polymerBasePath, storeBasePath);
        	
        	var aLastNotifiedState = collection.toLiveJSON().slice();
        	
        	var onCollectionChanged = self._oCollectionChangeListeners[storeBasePath] = self._oCollectionChangeListeners[storeBasePath] || _.debounce(function(){
            	var liveJSON  = collection.toLiveJSON();
            	self.notifySplices(polymerBasePath, aLastNotifiedState, liveJSON);
            	aLastNotifiedState = liveJSON.slice();
        	}, 50);
        	
        	self._listenTo(collection, 'reset sort', storeBasePath, function(collection, object) {
        		if(!object.previousModels){
        			return;
        		}
        		
        		onCollectionChanged();
        		self._unbindCollectionOneObserverAfter(object.previousModels, observer, storeBasePath);
        		self._bindCollectionOneObserverAfter(collection, observer, polymerBasePath, storeBasePath);
        	});
        	
        	self._listenTo(collection, 'remove', storeBasePath, function(model, collection, object) {
        		onCollectionChanged();
        		self.unbindModelObserver(observer, storeBasePath + '.' + self.createKeyForCollection(model, object.index));
        	});
        	/* jshint unused: false */
        	self._listenTo(collection, 'add', storeBasePath, function(model, collection, object) {
    			var nIndex = collection.indexOf(model);
    			onCollectionChanged();
    			self.bindModelOneObserver(model, observer, polymerBasePath + '.' + nIndex, storeBasePath + '.' + self.createKeyForCollection(model, nIndex));
        	});
        	/* jshint unused: true */
        },
        
        unbindModelObserver : function(observer, storeBasePath)
        {
        	var self = this;
        	var oListner = self.getListeners(storeBasePath);
        	
        	if(oListner && oListner.data && oListner.data && oListner.data instanceof Backbone.Collection)
    		{
        		self._unbindCollectionOneObserverAfter(oListner.data, observer, storeBasePath);
    		}
        	
        	self.unbindAndRemoveListener(storeBasePath);
        	
        	var aSplit = self.splitObserverAtFirst(observer);
        	
        	if(aSplit[0])
    		{
        		self.unbindModelObserver(aSplit[1], storeBasePath + '.' + aSplit[0]);
    		}
        },
        
        _unbindCollectionOneObserverAfter : function(collection, observer, storeBasePath, at, howMany)
        {
        	var self = this;
        	_.each(collection.slice(at, howMany), function(model, index){
        		self.unbindModelObserver(observer, storeBasePath + '.' + self.createKeyForCollection(model, index));
        	});
        },
        
        unbindCollectionObserver : function(collection, observer, storeBasePath)
        {
        	var self = this;
    		self.unbindAndRemoveListener(storeBasePath);
    		self._unbindCollectionOneObserverAfter(collection, observer, storeBasePath);
        },
        
        /**
         * Call bindModelOneObserver for each observer
         * @param model
         * @param observers
         * @param polymerBasePath
         */
        bindModel : function(model, observers, polymerBasePath)
        {
        	var self = this;
        	_.each(observers, function(observer){
        		self.bindModelOneObserver(model, observer, polymerBasePath, polymerBasePath);
        	});
        },
        
        /**
         * Call bindCollectionOneObserver for each observer
         * @param collection
         * @param observers
         * @param polymerBasePath
         */
        bindCollection : function(collection, observers, polymerBasePath)
        {
        	var self = this;
        	_.each(observers, function(observer){
        		self.bindCollectionOneObserver(collection, observer, polymerBasePath, polymerBasePath);
        	});
        },
        /* jshint unused: false */
        createKeyForCollection : function(model, index)
        {
        	return model.cid;
        },
        /* jshint unused: true */
        getListeners : function(storeBasePath)
        {
        	var self = this;
        	return self._oObserverListnersMap && self._oObserverListnersMap[storeBasePath];
        },
        
        storeListener : function(data, storeBasePath, andler)
        {
        	var self = this;
        	if(!self._oObserverListnersMap){
        		return;
        	}
        	var oListner = self.getListeners(storeBasePath) || {};
        	oListner.handlers = oListner.handlers || [];
        	oListner.handlers.push(andler);
        	if(!oListner.data){
        		oListner.data = data;
        	}
        	self._oObserverListnersMap[storeBasePath] = oListner;
        },
        
        unbindAndRemoveListener : function(storeBasePath)
        {
        	var self = this;
        	self.logger().info('Event un bind on ' + storeBasePath);
        	var oListner = self.getListeners(storeBasePath);
        	if(oListner) {
        		_.each(oListner.handlers, self._stopListening, self);
        	}
        	
        	if(self._oObserverListnersMap){
        		delete self._oObserverListnersMap[storeBasePath];
        	}
        },
        
        notifySplices : function(polymerBasePath, oldArray, NewArray)
        {
        	var self = this;
        	_.each(self.getDiffPatches(oldArray, NewArray), function(patch){
        		var removed = Array.prototype.splice.apply(oldArray, patch);
        		self.logger().info('_notifySpliceOn polymer el ' + polymerBasePath + ' index:' + patch[0] + ' added:' + patch.slice(2).length + ' removed:' + removed);
    			self.el._notifySplice(NewArray, polymerBasePath, patch[0], patch.slice(2).length, removed);
        	});
        },
        
        /* jshint ignore:start */
        getDiffPatches : function(oldArray, newArray)
        {
        	var aPatch = [];
        	
        	//Create a shadow copy
        	oldArray = oldArray.slice();
        	var currentIndex = 0;
        	var nMisMathced = 0;
        	
        	//Remove all elements from old array which are not exists in new array
        	while(currentIndex < oldArray.length){
        		
        		var item = oldArray[currentIndex];
        		var index = _.indexOf(newArray, item);
        		
        		if(index === -1){
        			nMisMathced = nMisMathced + 1;
        			currentIndex = currentIndex + 1;
        			
        			if((oldArray.length - currentIndex) > 0) {
        				continue;
        			}
        		} 
        		
        		if(nMisMathced > 0){
        			var args = [(currentIndex - nMisMathced), nMisMathced];
        			aPatch.push(args);
        			Array.prototype.splice.apply(oldArray, args);
        			currentIndex = (currentIndex - nMisMathced) + 1;
        			nMisMathced = 0;
        		} else {
        			currentIndex = currentIndex + 1;
        		}
        	}
        	
        	//Re order old array's elements as respective new array
        	var aIntersection = _.intersection(newArray, oldArray);
        	for(var i = 0; i < aIntersection.length; i = i + 1){
        		var item = oldArray[i];
        		if(item == aIntersection[i]){
        			continue;
        		}
        		
        		var index = _.indexOf(aIntersection, item);
        		var args = [i, 1];
    			aPatch.push(args);
    			Array.prototype.splice.apply(oldArray, args);
    			
    			args = [index, 0, item];
    			aPatch.push(args);
    			Array.prototype.splice.apply(oldArray, args);
    			
    			aIntersection = _.intersection(newArray, oldArray);
    			i = i -1;
    			
        	}
        	
        	//Add new elements in old array
        	for(var i = 0; i < oldArray.length; i = i + 1){
        		var item = oldArray[i];
        		if(item == newArray[i]){
    				continue;
        		}
        		
        		var added = 1;
        		while(newArray[i + added] != item){
        			added = added + 1;
        		}
        		
        		var args = [i, 0];
        		$.merge(args, newArray.slice(i, i + added));
    			aPatch.push(args);
    			Array.prototype.splice.apply(oldArray, args);
        	}
        	
        	//Add trailing new elements of array to old array
        	if(oldArray.length < newArray.length){
        		var args = [oldArray.length, 0];
        		$.merge(args, newArray.slice(oldArray.length, newArray.length));
    			aPatch.push(args);
    			Array.prototype.splice.apply(oldArray, args);
        	}
        	
        	return aPatch;
        },
        /* jshint ignore:end */
        
        _listenTo : function(data, eventName, storeBasePath, handler)
        {
        	var self = this;
        	self.logger().info('Event un bind on ' + storeBasePath + ' ' + eventName);
        	self.storeListener(data, storeBasePath, handler);
        	self.listenTo(data, eventName, handler);
        },
        
        _stopListening : function(handler)
        {
        	var self = this;
        	if(handler){
        		self.stopListening(null, null, handler);
        	}
        },
        
        parseChangePath: function(path)
        {
        	return path;
        },
        
        triggerChangeToPolymer : function(data)
        {
        	var self = this;
        	var sMethod = data.method || 'set';
        	var sPath = self.parseChangePath(data.path);
        	
        	self.logger().info('On polymer el : method=' + sMethod + ', path=' + sPath + ', value=' + data.value + ', data=' + data.data);
        	
        	if(self.el[sMethod])
    		{
        		if(sMethod == 'pop' || sMethod == 'shift')
    			{
        			self.el[sMethod](sPath);
    			}
        		else if(sMethod == 'splice' && data.data)
    			{
        			if(data.value){
        				self.el[sMethod](sPath, data.data.index, data.data.howMany);
        				_.each(data.value, function(o){
        					self.el.push(sPath, o);
        				});
        			}
        			else{
        				self.el[sMethod](sPath, data.data.index, data.data.howMany);
        			}
    			}
        		else
    			{
        			self.el[sMethod](sPath, data.value);
    			}
    		}
        },
        
        modelChanged : function(data)
        {
        	this.triggerChangeToPolymer(data);
        },
        
        splitObserverAtFirst : function(observer, delimiter)
        {
        	observer = observer || '';
        	delimiter = delimiter || '.';
        	
        	var nDotIndex = observer.indexOf(delimiter);
        	var aSplit = [];
        	
        	if(nDotIndex == -1) {
        		nDotIndex = observer.length;
        	}
        	
        	aSplit.push(observer.substr(0, nDotIndex));
        	
        	var sRest = observer.substr(nDotIndex + 1);
        	
        	if(sRest) {
        		aSplit.push(sRest);
        	}
        	return aSplit;
        },
        
        destroy: function()
        {
        	var self = this;
        	self.aPendingExecutions.forEach(function(handle){
        	    window.clearTimeout(handle);
        	});
        	self.stopListening();
        	self.el = undefined;
        	self.data = undefined;
        	self._oObserverListnersMap = undefined;
        	self._oCollectionChangeListeners = undefined;
        }
    });
    
    return BindModelToPolymer;
});
