define('modules/security/oauth2/module',['require','./OAuth2SecurityService','./OAuth2UserDAO'],function(require) {
  'use strict';

  var OAuth2SecurityService = require('./OAuth2SecurityService');
  var OAuth2UserDAO = require('./OAuth2UserDAO');

  return function(app) {
    app.modules.security = {
      service: new OAuth2SecurityService({
        app: app,
        dao: new OAuth2UserDAO({
          app: app
        })
      }),
    };

  };

});
