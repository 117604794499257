define('modules/core/views/App',['require','marionette','text!./../templates/app.hbs'],function(require) {
  'use strict';

  var Marionette = require('marionette');

  return Marionette.LayoutView.extend({

    __name__: 'com.dw.hisab.core.views.App',

    template: require('text!./../templates/app.hbs'),

    ui: {
      toast: 'paper-toast'
    },

    regions: {
      page: '#pageContent'
    },

    hide: function() {
      this.$el.hide();
    },

    show: function() {
      this.$el.show();
    },

    showUserInfo: function(mUser, signoutURL) {
      var self = this;
      var el = self.$('dw-user-info')[0];
      self.logger().debug('showUserInfo:: invoked');
      el.set('model', mUser.toJSON());
      el.set('signoutUrl', signoutURL);
    }

  });

});
