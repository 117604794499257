define('components/polymerMetaRegistry',[],function() {
    'use strict';
    
    var registry = function(type, key, value) {
        var elIronMeta = document.createElement('iron-meta');
        if(type) {
            elIronMeta.set('type', type);
        }
        elIronMeta.set('key', key);
        elIronMeta.value = value;
    };
    
    return registry;
});
