define('modules/core/Router',['require','marionette'],function(require) {
  'use strict';

  var Marionette = require('marionette');

  var Router = Marionette.AppRouter.extend({
    __name__: 'core.Router',

    appRoutes: {
      'home': 'index',
      'verify/:token' : 'emailVerification',
      'forgot-password' : 'forgotPassword',
      'reset-password/:token' : 'resetPassword',
      '*notFound': 'notFound'
    }
  });

  return Router;
});
