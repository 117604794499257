define('plugins/logger/logger',['require','backbone','marionette','underscore','log4javascript','./config','./applyLog4JavascriptConfig'],function(require) {
    'use strict';

    var Backbone = require('backbone');
    var Marionette = require('marionette');
    var _ = require('underscore');
    
//    var logger = require('./NoOpLogger');
    var logger = require('log4javascript');
    
    var oLoggerConfig = require('./config');
    require('./applyLog4JavascriptConfig')(oLoggerConfig);
    
    return function() {
        function getLoggerFn(prefix, logger) {
            return function() {
                var sLoggerName = this.__name__ || '';
                if (!sLoggerName) {
                    sLoggerName = prefix;
                }
                else {
                    sLoggerName = prefix + '.' + sLoggerName;
                }
    
                return logger.getLogger(sLoggerName);
            };
        }
        
        var fnTextLogger = getLoggerFn('t', logger);
        var fnConsoleLogger = getLoggerFn('c', logger);
        
        
        var aClass = [Backbone.View, Backbone.Model, Backbone.Collection, Backbone.Router, Marionette.Object,
                      Marionette.Controller, Marionette.Application, Marionette.Controller, Marionette.Region];
        
        _.each(aClass, function(Class){
            Class.prototype.logger = fnTextLogger;
            Class.prototype.console = fnConsoleLogger;
        });
        
        
        
    };
});

