define('modules/core/BootstrapService',['require','marionette','jquery'],function(require) {
  'use strict';

  var Marionette = require('marionette');
  var $ = require('jquery');

  var Service = Marionette.Object.extend({
    __name__: 'com.dw.hisab.core.BootstrapService',

    name: 'bootstrap',

    initialize: function(app) {
      var self = this;
      self.app = app;
    },

    /**
     * This is sample start function which can be used to setup user's account
     * if not already setup.
     */
    _setupAccountStart: function() {
      var self = this;
      var deferred = $.Deferred();

      self.app.services.users.currentUser().done(function(mUser) {
        self.app.mUser = mUser;
        // If user already has account setup, we are done.
        if (mUser.get('permissions').size() !== 0) {
          deferred.resolve();
          return;
        }

        // Otherwise, setup default account for login user
        self.app.services.accounts.create().done(function() {
          deferred.resolve();
        }).fail(function() {
          deferred.reject('Failed to create default account');
        });
      }).fail(function() {
        deferred.reject('Failed to read login user\'s data');
      });
      return deferred.promise();
    },

    /**
     * Invoked this function by security module once security checks are passed.
     * No route will be triggered until promise object returned by this object
     * is resolved. user can pass redirect URL as argument of resolve function
     * to override user accessed URL.
     */
    start: function() {
      var self = this;
      var deferred = $.Deferred();
      self.logger().debug('Waiting for 1 seconds... bootstrap time');
      window.setTimeout(function() {
        deferred.resolve();
        // this.app.modules.home.usersService.read('me').done(function(mUser) {
        // self.app.app.mCurrentUser = mUser;
        // deferred.resolve();
        // });
      });
      return deferred.promise();
    }

  });

  return Service;
});

