define('modules/core/views/UserWelcomePage',['require','components/PolymerView'],function (require) {
    'use strict';

    var PolymerView = require('components/PolymerView');
    return PolymerView.extend({
        
        __name__ : 'com.dw.aristata.core.views.UserManageMent',
        
        template : '<user-welcome></user-welcome>',
        
    });

});
