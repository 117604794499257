define('modules/core/views/NotFound',['require','marionette','text!./../templates/not-found.hbs'],function (require) {
    'use strict';

    var Marionette = require('marionette');
    
    return Marionette.ItemView.extend({
        
        template : require('text!./../templates/not-found.hbs'),

    });

});
