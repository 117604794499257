define('modules/core/Controller',['require','underscore','./views/App','components/BaseController','./views/NotFound','./views/UserWelcomePage','./views/EmailVerificationPage','./views/ForgotPasswordPage','./views/ResetPasswordPage','jquery','components/polymerMetaRegistry'],function(require) {
  'use strict';

  // var Marionette = require('marionette');
  var _ = require('underscore');
  var AppView = require('./views/App');
  var BaseController = require('components/BaseController');
  var NotFoundView = require('./views/NotFound');
  var UserWelcomePage = require('./views/UserWelcomePage');
  var EmailVerificationPage = require('./views/EmailVerificationPage');
  var ForgotPasswordPage = require('./views/ForgotPasswordPage');
  var ResetPasswordPage = require('./views/ResetPasswordPage');
  var $ = require('jquery');

  var Controller = {

    __name__: 'core.Controller',

    signoutURL: undefined,

    initialize: function(options) {
      var self = this;
      var app = self.app = options.app;
      app.on('start', function(options) {
        self.signoutURL = options.oauth2security.logoutURL;
        require('components/polymerMetaRegistry')('constants', 'cloudinary',
                options.cloudinary);
      });
      self.createAppView();
      self.initPopupRegion();

      app.reqres.setHandler('confirm', _.bind(self.showConfirmDialog, self));
      app.commands.setHandler('toast', _.bind(self.showToast, self));
      app.commands.setHandler('navigate:notFound', _.bind(self.notFound, self));
      self.listenTo(app.vent, 'current-user-ready', _.bind(
              self.onCurrentUSerReady, self));
    },

    createAppView: function() {
      var self = this;
      var appView = self.app.view = new AppView();
      appView.hide();
      var mainRegion = self.app.getRegion('main');
      if (mainRegion) {
        mainRegion.show(appView);
      } else {
        $('body').empty().append(appView);
      }
    },

    initPopupRegion: function() {
      var self = this;
      self.app.popup.on('show', function(view) {
        window.setTimeout(function() {
          if (view.polymerEl) {
            view.polymerEl.open();
          }
        }, 100);
      });
    },

    index: function() {
      var self = this;
      var vWelcome = new UserWelcomePage();
      self.showPage(vWelcome);
    },

    emailVerification : function() {
      var self = this;
      var vPage = new EmailVerificationPage();
      self.showPage(vPage, 'Complete your sign up', 'email-verifiation');

      window.dataLayer && window.dataLayer.push({
        'event': 'virtual-pageview',
        'virtualPage':'/verify'
      });
    },

    forgotPassword : function() {
      var self = this;
      var vPage = new ForgotPasswordPage({
        appName : self.app.options.appName
      });
      self.showPage(vPage, 'Forgot password?', 'forgot-password');

      //Track GTM event for Forgot password page
      window.dataLayer && window.dataLayer.push({
        'event': 'virtual-pageview',
        'virtualPage': '/forgot-password'
      });
    },

    resetPassword : function() {
      var self = this;
      var vPage = new ResetPasswordPage();
      self.showPage(vPage, 'Reset your password', 'reset-passowrd');
    },

    notFound: function() {
      var self = this;
      self.logger().debug('notFound:: Controller invoked');
      var notFoundView = new NotFoundView();
      self.showPage(notFoundView, 'Not Found', 'not-found');
      // TODO: Hide header region
    },

    onCurrentUSerReady: function(mUser) {
      var self = this;
      self.showUserInfo(mUser);
    },

    showUserInfo: function(mUser) {
      var self = this;
      self.logger().debug(
              'showUserInfo:: mUser.email=' + mUser.get('primaryEmail'));
      self.app.view.showUserInfo(mUser, self.signoutURL);
    },

    showToast: function(prop, type) {
      var self = this;
      var oInitProp = {};
      var oDefaultProp = {
        'text': '',
        'duration': 3000
      };
      var oProperties = _.extend(oInitProp, oDefaultProp, prop);

      // If type is not specified then set "DEFAULT"
      if (!type) {
        type = 'DEFAULT';
      }
      // If text is not available then debug level log and return
      if (oProperties.text && oProperties.text.length === 0 ||
              !oProperties.text) {
        self.logger().debug('showToast:: invoked');
        return;
      }
      // Array of toasts
      var aToasts = this.app.view.ui.toast;
      // Toast element filter from array of toast
      var toastEl = aToasts.filter('[type =' + type + ']')[0];

      // Set toast properties
      for ( var sProp in oProperties) {
        toastEl.set(sProp, oProperties[sProp]);
      }
      // Show toast
      toastEl.show();
    },

    showConfirmDialog: function(keyWord, buttonCaption) {
      var self = this;
      var deferred = $.Deferred();
      if (!self.confirmDialogEl) {
        self.confirmDialogEl = $('#confirmDialog').find('confirm-dialog')[0];
      }
      var confirmDialogEl = self.confirmDialogEl;

      if (keyWord) {
        confirmDialogEl.set('keyWord', keyWord);
      }

      confirmDialogEl.set('buttonCaption', buttonCaption || 'Delete');

      confirmDialogEl.fire('cancel');
      confirmDialogEl.set('text', '');

      var onCancel = function() {
        if (confirmDialogEl.closingReason.confirmed) {
          deferred.resolve();
        } else {
          deferred.reject();
        }
        confirmDialogEl.removeEventListener('cancel', onCancel);
      };

      if (keyWord != 'stop') {

        confirmDialogEl.addEventListener('cancel', onCancel);
        confirmDialogEl.open();
      }
      return deferred.promise();
    }
  };

  return BaseController.extend(Controller);

});

