define('modules/core/module',['require','./Controller','./Router','./BootstrapService'],function(require) {
  'use strict';

  var Controller = require('./Controller');
  var Router = require('./Router');
  var BootstrapService = require('./BootstrapService');

  return function(app) {

    app.module('home', function() {

      var controller = new Controller({
        app: app
      });

      var router = new Router({
        controller: controller
      });

      app.modules.core = {
        controller: controller,
        bootstrapService: new BootstrapService({
          app: app
        }),
        _router: router
      };

      /**
       * Commands, basically this modules "public api".
       */
      app.commands.setHandler('navigate:home', function() {
        controller.index();
        router.navigate('home');
      });

    });

  };

});
