define('plugins/logger/applyLog4JavascriptConfig',['require','underscore','log4javascript'],function(require) {
    'use strict';
    
    var _ = require('underscore');
    var log4javascript = require('log4javascript');
    
    function addAppenders(){
        // string logger
        var textLogger = log4javascript.getLogger('t');
        var consoleLayoutAppender = new log4javascript.BrowserConsoleAppender(); 
        var consoleLayout = new log4javascript.PatternLayout('%d{HH:mm:ss} %-5p [ %c ]:- %m');
        consoleLayoutAppender.setLayout(consoleLayout);
        consoleLayoutAppender.setThreshold(log4javascript.Level.ALL);
        textLogger.addAppender(consoleLayoutAppender);

        // console apppender
        var nullLogger = log4javascript.getLogger('c');
        var consoleNullAppender = new log4javascript.BrowserConsoleAppender();
        var nullLayout = new log4javascript.NullLayout();
        consoleNullAppender.setLayout(nullLayout);
        consoleNullAppender.setThreshold(log4javascript.Level.ALL);
        nullLogger.addAppender(consoleNullAppender);
    }
    
    function configureLogLevels(aLoggerConfig){
        var all = 'all',
            trace = 'trace', 
            debug = 'debug',
            info = 'info',
            warn = 'warn',
            error = 'error',
            fatal = 'fatal',
            off = 'off';
        
        _.each(aLoggerConfig, function(oLoggerConfig){
            var oLevel;
            switch(oLoggerConfig.level.toLowerCase()){
                case all:
                    oLevel = log4javascript.Level.ALL;
                    break;
                case trace: 
                    oLevel = log4javascript.Level.TRACE;
                    break;
                case debug:
                    oLevel = log4javascript.Level.DEBUG;
                    break;
                case info:
                    oLevel = log4javascript.Level.INFO;
                    break;
                case warn:
                    oLevel = log4javascript.Level.WARN;
                    break;
                case error:
                    oLevel = log4javascript.Level.ERROR;
                    break;
                case fatal:
                    oLevel = log4javascript.Level.FATAL;
                    break;
                case off:
                    oLevel = log4javascript.Level.OFF;
                    break;
                default : 
                    oLevel = log4javascript.Level.OFF;
            }
            log4javascript.getLogger('t.' + oLoggerConfig.logger).setLevel(oLevel);
            log4javascript.getLogger('c.' + oLoggerConfig.logger).setLevel(oLevel);
        });
    }
    
    function applyConfig(oConfig){
        /// Default log level
        log4javascript.getRootLogger().setLevel(log4javascript.Level.INFO);
        
        addAppenders();
        configureLogLevels(oConfig.loggers);
    }
    
    return applyConfig;

});
