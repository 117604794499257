define('modules/security/oauth2/OAuth2UserDAO',['require','../../../components/BaseDAO'],function(require) {
  'use strict';

  var BaseDAO = require('../../../components/BaseDAO');
  var OAuth2UserDAO = BaseDAO.extend({
    __name__: 'com.dw.security.OAuth2DAO',

    name: 'oauth2User',

    initialize: function(options) {
      var self = this;
      self.logger().debug('OAuth2UserDAO Init');
      BaseDAO.prototype.initialize.call(self, {
        app: options.app,
        clientName: 'oauth2users',
        endpointName: 'users'
      });
    },

    getCurrentUser: function() {
      return this.read('me');
    }

  });

  return OAuth2UserDAO;
});
