define('modules/core/views/ResetPasswordPage',['require','components/PolymerView','underscore','URI','URITemplate'],function (require) {
    'use strict';

    var PolymerView = require('components/PolymerView');
    require('underscore');
    window.URI = require('URI');
    window.URITemplate = require('URITemplate');
    
    return PolymerView.extend({
        
        __name__ : 'com.dw.aristata.core.views.ResetPasswordPage',
        
        template : '<dw-oauth2-reset-password></dw-oauth2-reset-password>',
        
    });

});
