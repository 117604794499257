define('app',['require','backbone','marionette','autoload/vendors','autoload/plugins','autoload/modules','uuid','components/polymerMetaRegistry'],function (require) {
    'use strict';

    var Backbone = require('backbone');
    var Marionette = require('marionette');

    /**
     * @type {Marionette.Application}
     */
    var app = window.app = new Marionette.Application();

    app.addRegions({
      main : '#container',
      popup : '#popupContainer',
      confirm : '#confimDialog'
    });
    
    app.modules = {};
    
    /**
     * Load our application
     */
    require('autoload/vendors')(app);
    require('autoload/plugins')(app);
    require('autoload/modules')(app);

    app.on('start', function (options) {
      
        //Register service as Meta
        var uuid = require('uuid');
        require('components/polymerMetaRegistry')(undefined, 'idGenerator', {
          generate : function() {
            return uuid.v4();
          }
        });
        
        app.options = options;
        if (!Backbone.history.start({ pushState : options.pushState })) {
            Backbone.history.navigate('home', { trigger : true });
        }
    });

    return app;
});







