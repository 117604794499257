define('modules/security/oauth2/OAuth2User',['require','backbone'],function(require) {
  'use strict';

  var Backbone = require('backbone');

  var User = Backbone.Model.extend({
    defaults: {
      fname: undefined,
      lname: undefined,
      gender: undefined,
      primaryEmail: undefined,
      profilePicUrl: undefined
    }
  });

  return User;
});
