define('components/BaseController',['require','underscore','marionette','jquery'],function (require) {
    'use strict';

    var _ = require('underscore');
    var Marionette = require('marionette');
    var $ = require('jquery');

    return Marionette.Object.extend({

        __name__ : 'com.dw.BaseController',

        polymerEvents : {},


        showPage : function(vPage, sPageTitle, sPageName) {
        	var self = this;
            var hash = window.location.hash || '';
            var hideHeader = sPageName == 'reset-password' || hash.startsWith('#reset-password');
            var hideFooter = sPageName == 'reset-password' || hash.startsWith('#reset-password');
        	if(!self.isCurrentPopupView())
    		{
	        	self.app.popup.reset();
    		}

        	self._bindEvents(vPage, self.pageEvents);
        	self.app.view.page.show(vPage);
            $('title').text(sPageTitle || '');
            $('app-page')[0].set('pageName', sPageName);
            $('app-page')[0].set('hideHeader', hideHeader);
            $('app-page')[0].set('hideFooter', hideFooter);
        },

        showPopup : function(vPopup, events) {
        	var self = this;
        	self._bindEvents(vPopup, events || self.popupEvents);
        	self.app.popup.show(vPopup);
        },

        isCurrentPageView : function()
        {
            var self = this;
        	if(this.pageClass){
        		return self.app.view.page.currentView instanceof this.pageClass;
        	}
        },

        isCurrentPopupView : function()
        {
        	var self = this;
        	if(self.popupClass){
        		var aClass = self.popupClass;
        		if(!_.isArray(self.popupClass)){
        			aClass = [self.popupClass];
        		}
        		return _.any(aClass, function(Class){
        			return self.app.popup.currentView instanceof Class;
        		});
        	}
        },

        _bindEvents : function(view, events) {
            var self = this;
            view.on('render', function(){
                if(!view.polymerEl) {
                    return;
                }
                _.each(events, function(listener, event) {
                    view.polymerEl.addEventListener(event, _.bind(self[listener], self));
                });
            });

        }

    });

});
