define('components/PolymerBindingUtil',['require','backbone','components/BackbonePolymerBinder'],function(require) {
    'use strict';
    
    var Backbone = require('backbone');
    var BackbonePolymerBinder = require('components/BackbonePolymerBinder');
    
    var PolymerBindingUtil = {
    		
		/**
		 * @param data				//Mandatory | Backbone.Model or Backbone.Collection
		 * @param polymerEl			//Mandatory | Polymer element
		 * @param observers			//Optional  | String or Array
		 * @param polymerBasePath	//Optional  | String
		 */
		bind : function(data, polymerEl, observers, polymerBasePath)
		{
			if(!polymerBasePath)
			{
				if(data instanceof Backbone.Model){
					polymerBasePath = 'model';
	            }
	            else if(data instanceof Backbone.Collection){
	            	polymerBasePath = 'models';
	            }
			}
			var backbonePolymerBinder = new BackbonePolymerBinder({
				data : data,			
	    		polymerEl : polymerEl,		
	    		observers : observers,		
	    		polymerBasePath : polymerBasePath
			});
			
			return {
				destroy : function(){
					backbonePolymerBinder.destroy();
				}
			};
		}
    };
    return PolymerBindingUtil;
});
