require(['app'], function (app) {
    'use strict';

    /**
     * Start our application after all imports in HTML file is loaded and all elements have been registered
     */
    function onReady() {
      app.start(window.application);
    }
    
    if(window.webComponentsReady){
         onReady();
    } else {
         window.addEventListener('WebComponentsReady',  onReady);
    }
});
define("bootstrap", function(){});

