define('autoload/plugins',['require','plugins/logger/logger','plugins/models','plugins/RestClients'],function (require) {
    'use strict';

    return function (app) {
      /** Autoload plugins */
      require('plugins/logger/logger')(app);
      require('plugins/models');
      require('plugins/RestClients')(app);
    };

});


