define('components/BaseDAO',['require','jquery','jquery.rest','marionette'],function(require) {
    'use strict';

    require('jquery');
    require('jquery.rest');
    var Marionette = require('marionette');
    
    var BaseDAO = Marionette.Object.extend({
        __name__ : 'com.dw.core.BaseDAO',

        clientName : undefined,
        endpointName : undefined,
        app: undefined,
        endpoint : undefined,

        initialize : function(options) {
            var self = this;
            var clientName = self.clientName = options.clientName || 'aristata';
            var endpointName = self.endpointName = options.endpointName;
            self.app = options.app;
            self.logger().debug('BaseDAO Init. clientName :' + clientName + ', endpointName : ' + endpointName);
        },
        
        read : function() {
            return this._proxy('read', arguments);
        },
        
        create : function() {
            return this._proxy('create', arguments);
        },
        
        update : function() {
            return this._proxy('update', arguments);
        },
        
        del : function() {
            return this._proxy('del', arguments);
        },
        
        addVerb : function(){
            return this._proxy('addVerb', arguments);
        },
        
        _proxy:  function(fnName, args) {
            var self = this;
            var endpoint = self._endpoint();
            return endpoint[fnName].apply(endpoint, args);
        },

        _endpoint : function() {
            var self = this;
            
            // If we have already created endpoint then use it
            if(self.endpoint) {
                return self.endpoint;
            }
            
            //Otherwise, create new endpoint.
            var restClient = self.app.restClients[self.clientName];
            self._initEndpoint(restClient);
            return (self.endpoint = restClient[self.endpointName]);
        },
        
        _initEndpoint : function(restClient) {
            restClient.add(this.endpointName);
        }
    });

    return BaseDAO;
});
