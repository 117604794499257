define('plugins/logger/config',[],function() {
    'use strict';
    
    console.log('loggerConfig read.');
    return {
        loggers : [{
            logger : 'c.HomePage',
            level : 'warn'
        }, {
            logger : 'com.dw.hisab',
            level : 'debug'
        }, {
            logger : 'com.dw.hisab.core.RestClients',
            level : 'trace'
        }, {
            logger : 'com.dw.aristata.home.views.Index',
            level : 'trace'
        }]
    };
});

