define('components/PolymerView',['require','backbone','marionette','components/PolymerBindingUtil','underscore'],function(require) {
    'use strict';
    
    // var _ = require('underscore');
    var Backbone = require('backbone');
    var Marionette = require('marionette');
    var PolymerBindingUtil = require('components/PolymerBindingUtil');
    var _ = require('underscore');
    
    return Marionette.ItemView.extend({
        __name__ : 'com.dw.views.PolymerView',
        
        
        defPolymerBindings : {
          model : {
            polymerProperty : 'model',
            observers : []
          },
          collection : {
            polymerProperty : 'models',
            observers : []
          }
        },
        
        aOptionsBindings : [],
        
        
        aPolymerBindings : [],
        
//        polymerBindings : {},
        
        /**
         * Represents paths (Relational model) to be watched during Backbone model to Polymer element binding
         */
        observers : [],
        
        onBeforeRender: function(){
        	var self = this;
        	self.defPolymerBindings = _.extend({}, self.defPolymerBindings);
        	if(self.modelObservers && self.model && self.model.observers) {
        	  self.defPolymerBindings.model.observers = self.model.observers;
        	}
        	else if(self.observers) {
        	  //To make updated code backward compatible, set self.observers to defPolymerBindings
        	  self.defPolymerBindings.model.observers = self.observers;
        	  self.defPolymerBindings.collection.observers = self.observers;
        	}
        	
        },
        
        onRender: function() {
            var self = this;
            self.$el.data('api', self);
            
            //Use time out to append element into document otherwise IE and Firefox can
            //not select proper element from template
            window.setTimeout(function() {
              self._findAndSetPolymerEl();
              self._applyPolymerBindings();
              self._applyOptionsBindings();
            }, 0);
        },
        
        _applyPolymerBindings : function() {
          var self = this;
          
          if (!self.polymerEl) {
              throw new Error('this.polymerEl not found');
          }
          //TODO check its polymer el or not
          
          //Set instance specific array for 'polymerBindings'
          self.aPolymerBindings = [];
          var oEffectiveBindings = _.extend({}, self.defPolymerBindings, self.polymerBindings);
          for (var prop in oEffectiveBindings) {
            self._applyOneBinding(prop, oEffectiveBindings[prop]);
          }
        },
        
        _applyOptionsBindings : function() {
          var self = this;
          var aOptions = _.union(_.keys(self.options), self.aOptionsBindings);
          _.each(aOptions, function(sOptionName) {
            if(self.polymerEl.set) {
              self.polymerEl.set(sOptionName, self.getOption(sOptionName));
            }
          });
        },
        
        _applyOneBinding : function(prop, config) {
          var self = this;
          var data = self._getModelOrCollection(prop);
          if(!data) {
            self.logger().debug('_applyOneBinding:: No data found corresponding to property "' +
                    prop + '"');
            return;
          }

          if(self.polymerEl.set) {
            self.polymerEl.set(config.polymerProperty, data.toLiveJSON());
          }
          var binder = PolymerBindingUtil.bind(data, self.polymerEl, config.observers, config.polymerProperty);
          self.aPolymerBindings.push(binder);
        },
        
        _getModelOrCollection : function(prop) {
          var value = this[prop] || this.options[prop];
          if(!value || (!(value instanceof Backbone.Model) &&
                  !(value instanceof Backbone.Collection))) {
            this.logger().debug('_getModelOrCollection:: prop not found, or prop isn\'t instance of' +
                    'Backbone.Model or Backbone.Collection. prop=' + prop);
            return;
          }
          
          return value;
        },
        
        _findAndSetPolymerEl : function() {
            var self = this;
            self.polymerEl = self.$('>')[0];
        },
        
        onDestroy: function(){
           var self = this;
           _.each(self.aPolymerBindings, function(binder){
             binder.destroy();
           });
           self.aPolymerBindings = [];
           self.polymerEl = undefined;
        }
    });
});
