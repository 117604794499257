define('plugins/RestClients',['require','jquery','jquery.rest','marionette','underscore'],function(require) {
    'use strict';
    
    var $ = require('jquery');
    require('jquery.rest');
    var Marionette = require('marionette');
    var _ = require('underscore');

    var RestClients = Marionette.Object.extend({
        __name__ : 'com.dw.RestClients',

        accessToken : undefined,

        config : {},

        initialize : function(options) {
            var self = this;
            self.logger().debug('RestClientFactory Init');
            var app = self.app = options.app;
            
            self.app.on('start', function(options){
                self.config = options.restClients;
            });
            
            self.listenTo(app, 'access-token-ready', self._initClients);
        },

        _initClients : function(accessToken) {
            var self = this;
            self.logger().trace('_initClients invoked. accessToken=' + accessToken);
            self.accessToken = accessToken;
            $.ajaxSettings.traditional = true;
            _.each(self.config, function(path, clientName) {
                self.logger().debug('Creating client. name:' + clientName + ' path:' + path);
                self[clientName] = new $.RestClient(path, {
                    ajax : {
                        headers : {
                            Authorization : 'bearer ' + self.accessToken
                        },
                        contentType : 'application/json; charset=UTF-8'
                    },
                    stringifyData : true
                });
            });
            self.app.vent.trigger('restClientsReady');
        }

    });

    return function(app){
        app.restClients = new RestClients({
            app : app
        });
    };
});
