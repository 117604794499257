define('plugins/models',['require','backbone','backboneRelational','underscore'],function(require){
    'use strict';
    
    var Backbone = require('backbone');
    require('backboneRelational');
    var _ = require('underscore');
    var models = {};
    Backbone.Relational.store.addModelScope(models);
    

    var syncObj = function(target, src) {
        var targetFields = _.keys(target);
        var srcFields = _.keys(src);
        var fieldsToBeRemoved = _.difference(targetFields, srcFields);
        
        _.each(fieldsToBeRemoved, function(field){
            target[field] = undefined;
        });
        
        _.each(srcFields, function(field) {
            var srcVal = src[field];
            if(srcVal instanceof Backbone.RelationalModel || srcVal instanceof Backbone.Collection) {
                target[field] = srcVal.toLiveJSON();
            } else {
                target[field] = srcVal;
            }
        });
        
    };
    
    Backbone.RelationalModel.prototype.toLiveJSON = function() {
        var self = this;
        if(self._oLiveJSON) {
            return self._oLiveJSON;
        }
        
        var json = self._oLiveJSON = {};
        syncObj (json, self.attributes);
        self._watchLiveJSON();
        return json;
    };
    
    Backbone.RelationalModel.prototype._watchLiveJSON = function() {
        var self = this;
        self.listenTo(self, 'change', function(){
            syncObj(self._oLiveJSON, self.attributes);
        });
    };
    
    Backbone.Model.prototype.getModelByPath = function(path) {
        if(!path) {
            return this;
        }
        
        var self = this;
        var model;
        var dotIndex = path.indexOf('.');
        if(dotIndex == -1) {
            return self._getModelByKey(path);
        }
        
        var key = path.substr(0, dotIndex);
        model = self._getModelByKey(key);
        if(model) {
            return model.getModelByPath(key, path.substr(dotIndex + 1));
        }
    };
    
    Backbone.Model.prototype._getModelByKey = function(key) {
        var self = this;
        var model = self.get(key); 
        return (model instanceof Backbone.Model) ? model : undefined;
    };
    
    Backbone.Collection.prototype.toLiveJSON = function() {
        var self = this;
        if(self._aLiveJSON) {
            return self._aLiveJSON;
        }
        
        var json = self._aLiveJSON = [];
        self._fillJSON();
        self._watchLiveJSON();
        return json;
    };
    
    Backbone.Collection.prototype._fillJSON = function() {
        var self = this;
        var json = self._aLiveJSON;
        self.each(function(model){
            json.push(model.toLiveJSON());
        });
    };
    
    Backbone.Collection.prototype._watchLiveJSON = function() {
        var self = this;
        self.listenTo(self, 'add', function(model){
            if(model instanceof Backbone.RelationalModel) {
                self._aLiveJSON.splice(self.indexOf(model), 0, model.toLiveJSON());
            }
        });
        
        self.listenTo(self, 'remove', function(model){
            if(model instanceof Backbone.RelationalModel) {
                var index = _.indexOf(self._aLiveJSON, model.toLiveJSON());
                self._aLiveJSON.splice(index, 1);
            }
        });
        
        
        self.listenTo(self, 'reset', function(){
            self._aLiveJSON.splice(0, self._aLiveJSON.length);
            self._fillJSON();
        });
    };
    

        
    (function(Backbone) {
        // Custom toJSON() START
        Backbone.RelationalModel.prototype.__toJSON = Backbone.RelationalModel.prototype.toJSON;
        

        var getViewDef = function(model, viewName) {
            var self = model;
            var view = {};
            if (viewName && viewName != 'default' && viewName != 'idOnly') {
                view = self.constructor.views ? self.constructor.views[viewName] : undefined;
                if (!view) {
                    throw new Error('No view found with requested name "' + viewName + '".');
                }

                // User hasn't requested any specific views/excludes. So, let's use default implementation.
                if (_.size(view) === 0) {
                    console.warn('No custom configuration found for view "' + viewName + '".');
                    view = {};
                }
            }
            return view;
        };
        
        
        var getWildCardExclude = function(view) {
          //Read include and exclude configuration from view.
            var bWildCardExclude = false;
            if (_.isString(view.exclude)) {
                if (view.exclude != '*') {
                    console.warn('exclude\'s value is string but not \'*\', so ignored. view=' + view);
                }
                bWildCardExclude = true;
            }
            return bWildCardExclude;
        };

        Backbone.RelationalModel.prototype.toJSON = function(viewName, aSerialized) {
            var self = this;

            var view = getViewDef(self, viewName);


            if (!aSerialized) {
                aSerialized = [];
            }

            // Prepare base json
            var json = {
                id : self.id,
                version : self.get('version')
            };
            
            //If already serialized return it
            if (_.indexOf(aSerialized, self) != -1 || viewName == 'idOnly') {
                return json;
            }

            aSerialized.push(self);

            //Read include and exclude configuration from view.
            var bWildCardExclude = getWildCardExclude(view);

            var aExcludes = _.isArray(view.exclude) ? view.exclude : [];

            var aIncludes = _.isObject(view.include) ? view.include : [];

            //Fill json
            _.each(self.attributes, function(value, key) {
                if ((_.indexOf(aExcludes, key) != -1 || bWildCardExclude) && !aIncludes[key]) {
                    //key is excluded, so no need to do add it to json.
                    return;
                }

                if (value instanceof Backbone.RelationalModel) {
                    json[key] = value.toJSON(aIncludes[key], aSerialized);
                    return;
                }

                if (value instanceof Backbone.Collection) {
                    json[key] = value.toJSON(aIncludes[key], aSerialized);
                    return;
                }

                json[key] = value;
            });
            return json;
        };
        
        
        Backbone.Collection.prototype.toJSON = function(viewName, aSerialized){
            var self = this;
            if (!aSerialized) {
                aSerialized = [];
            }
            var aJSON = [];
            self.each(function(model){
                aJSON.push(model.toJSON(viewName, aSerialized));
            });
            return aJSON;
        };
    })(Backbone);
    // Custon toJSON() END
    
    
    return models;
});
